import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../services/Main/Api'
import {
  Alert, AlertTitle,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card, CardActionArea, CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider, FormControlLabel,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem, Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { useSnackbar } from 'notistack'
import Context from '../../../contexts/Context'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment/moment'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import {
  AccountBalance,
  Alarm,
  Article,
  CancelOutlined,
  ContactMailOutlined, Download, PictureAsPdf,
  PixOutlined,
  Receipt
} from '@mui/icons-material'
import Masks from '../../../components/Shared/Masks'
import { MobileDatePicker } from '@mui/x-date-pickers'
import InternalNotesCard from '../../../components/Shared/InternalNotesCard'
import NewInternalNotesCard from '../../../components/Shared/NewInternalNotesCard'
import SignedRecipientTransferMakeMenu from "./SignedRecipientTransferMakeMenu";

const SignedRecipientTransferDetail = () => {
  const { filters, setFilters, loadingModal, setLoadingModal } = useContext(Context)

  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const [dateInput, setDateInput] = useState(moment().format("DD/MM/YYYY"))
  const [valueFormated, setValueFormated] = useState(0)
  const [value, setValue] = useState(0)
  const [isSaving, setIsSaving] = useState(false)
  const [sendEmail, setSendEmail] = useState(false)
  const [sendWhatsapp, setSendWhatsapp] = useState(false)
  const [isPaid, setIsPaid] = useState(false)
  const [updateList, setUpdateList] = useState(false)

  const { user } = useContext(Context)

  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()
  const reference = data ?
      data?.reference_mode_company.value : null;

  console.log('data',reference)

  useEffect(() => {

    getSignedRecipientTransferId()
    // getCondo()
    window.scrollTo(0, 0)


  }, [])

  function handleDialogClose() {
    setOpenDialog(false)
  }

  function handleCancelTransfer() {
    setIsSaving(true)
    const data = {
      id: params.id
    }
    api.put("/signed-recipient-transfer/", data).then(response => {
      console.log(response)
      getSignedRecipientTransferId()
      setIsSaving(false)
      // setTimeout(() => {
      //setIsSuccess(true)
      // getRentContractFee()
      // resetFields()

      // }, 2000);

    }).catch(error => {
      console.log(error)
      setIsSaving(false)
    }).finally(data => {

      setTimeout(() => {
        // setActiveBackdrop(false)
        // setIsSuccess(false)
      }, 4000);
    })
  }

  function handleUpdateTransfer(handleManualPayment) {
    setIsSaving(true)
    const data = {
      id: params.id
    }
    api.put("/signed-recipient-transfer/action/", data).then(response => {
      console.log(response)
      getSignedRecipientTransferId()
      setIsSaving(false)
      // setTimeout(() => {
      //setIsSuccess(true)
      // getRentContractFee()
      // resetFields()

      // }, 2000);

    }).catch(error => {
      console.log(error)
      setIsSaving(false)
    }).finally(data => {

      setTimeout(() => {
        // setActiveBackdrop(false)
        // setIsSuccess(false)
      }, 4000);
    })
  }

  const getSignedRecipientTransferId = async () => {
    setIsLoading(true)
    setIsSaving(true)
    const url = `signed-recipient-transfer/${params.id}/`
    let result = await api.get(url).then(response => {
      console.log(response.data)
      setData(response.data)
      setIsLoading(false)
      setIsSaving(false)
    })
  }

  function createManualTransfer(){
    setOpenDialog(false)
    setIsSaving(true)
    const data = {
      id: params.id,
      send_email_recipient:sendEmail,
      send_whatsapp_recipient:sendWhatsapp,
      auto_transfer_scheduled: moment(dateInput, "DD/MM/YYYY").toISOString()
    }
    api.post("/signed-recipient-transfer/", data).then(response => {

      getSignedRecipientTransferId()
      setOpenDialog(false)
      setIsSaving(false)
      // setTimeout(() => {
        //setIsSuccess(true)
        // getRentContractFee()
        // resetFields()

     // }, 2000);

    }).catch(error => {
      console.log(error)
      setOpenDialog(true)
      setIsSaving(false)
    }).finally(data => {
      setIsSaving(false)
      setTimeout(() => {
        // setActiveBackdrop(false)
        // setIsSuccess(false)
      }, 4000);
    })
  }

  function addDebitTransfer() {
    setIsSaving(true)
    const data = {
      id: params.id
    }
    api.post("/signed-recipient-transfer/action/", data).then(response => {
      console.log(response)
      getSignedRecipientTransferId()
      setIsSaving(false)


    }).catch(error => {
      console.log(error)
      setIsSaving(false)
    }).finally(data => {


    })
  }

  function handleManualPayment (){
    setIsPaid(true);
    setIsLoading(true)
    setUpdateList(!updateList);
    getSignedRecipientTransferId()
  }


  console.log('data', data)

  return (
    <>

      <Helmet>
        <title>Repasse - Bloco B3</title>
      </Helmet>
        <Backdrop
          sx={{ color: '#fff', zIndex: 1500, }}
          open={loadingModal}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>

        {/*{!isLoading &&*/}
      { data &&
      <Grid
        container
        spacing={2}
        justifyContent={'space-between'}
        sx={{ flexGrow: 1, padding: "3rem",   }}
      >
        {data?.has_updates != 0  && data?.status.id !== "PAID" && <Grid md={12}>
          <Box sx={{padding: "2rem 1rem"}}>

             <Alert severity="warning">
              <AlertTitle>Repasse desatualizado!</AlertTitle>
              Detectamos que seu repasse está desatualizado, foi encontrado uma diferença no total de {Masks.money(data?.has_updates.toString())}.
              <Box>
                {data?.status.id === "SCHEDULED" ?
                  <Box>
                  <Button disabled >Atualizar Repasse</Button>
                  <small>Para atualizar o repasse primeiro você precisa cancelar a transferência.</small>
                  </Box>
                : <Button  onClick={()=>{handleUpdateTransfer()}}>Atualizar Repasse</Button>
                }
              </Box>

            </Alert>

          </Box>

        </Grid> }
        <Grid md={8}>
          <Card
            sx={{padding:"1rem", margin: "0 1rem " }}>
            { !isLoading &&
              <>
                <CardContent sx={{paddingRight:"0rem"}}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent={'space-between'}
                    sx={{ flexGrow: 1}}
                  >
                    <Grid xs={6}>
                      <Box>
                        <Typography variant="h6"  sx={{
                          color: 'primary.main'
                        }}>{data?.number}</Typography>
                      </Box>
                    </Grid>
                    <Grid xs={6}>
                      <Box
                        border
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                          alignItems: 'center'

                        }}
                      >
                        { data?.status.id === "PENDING" || data?.status.id === "ERROR"  ?
                          <Button size={"small"}
                           variant="contained"
                                  onClick={()=>{
                                      setOpenDialog(true)
                        }}>Criar Repasse</Button> : ""}

                        { data?.status.id !== "OPEN" && data?.status.id !== "PENDING" && data?.status.id !== "ERROR" &&
                          <Link
                          href={data?.receipt_owner}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                            <Button size={"small"}
                                    //color={'neutral'}
                                    variant="contained"
                                    sx={{textTransform: 'none'}}
                                    startIcon={<Download/>}
                            > Demonstrativo</Button>
                      </Link>
                        }
                        <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                        >
                        <Chip
                          color={data?.status.id === 'PAID' ? 'success' :
                            data?.status.id === 'PENDING' ? 'warning' :
                              data?.status.id === 'SCHEDULED' ? 'secondary' :
                                data?.status.id === 'FINALIZED' ? 'success' :
                                  data?.status.id === 'PROCESSING' ? 'primary' :
                                    data?.status.id === 'ERROR' ? 'error' :
                                      data?.status.id === 'DUE' ? 'error' :
                                      data?.status.id === 'DEBIT' ? 'error' :
                                        data?.status.id === 'FAILED' ? 'error' :
                                        data?.status.id === 'CANCELLED' ? 'error' :
                                          data?.status.id === 'OPEN' && 'info'}
                          size="small"
                          variant="outlined"
                          sx={{marginLeft:"1rem"}}
                          label={data?.status.value}/>
                        <Typography
                            sx={{
                              mt: '0.3rem',
                              fontSize: '0.7rem',
                              marginLeft:"1rem",
                            }}
                        >
                          {data?.finalized_by === 'MANUAL' && 'Baixa Manual'}
                        </Typography>
                      </Box>
                      <Box>
                        {(data?.status.id === "OPEN" ||
                                data?.status.id === "PENDING" ||
                                data?.status.id === "FINALIZED" ||
                                data?.status.id === "ERROR") &&
                            <SignedRecipientTransferMakeMenu
                                rentRecipientTransfer={data}
                                handleManualPayment={handleManualPayment}
                            />
                        }
                      </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider/>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell >Código</TableCell>
                      <TableCell>Descrição</TableCell>

                      <TableCell width={150}>Valor</TableCell>


                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { data?.fees.map(fee =>
                    <TableRow>

                      <TableCell>
                        COB-{fee.id}
                      </TableCell>
                      <TableCell>
                        {fee.description}
                      </TableCell>
                      <TableCell sx={fee.total < 0 ? {color:"error.main"} : {}}>
                        {Masks.money(fee.total.toString())}
                      </TableCell>
                    </TableRow> )}

                    {data?.percentage < 100 &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align={"right"}><Typography level={"title-lg"}>Subtotal:</Typography></TableCell>
                      <TableCell><Typography level={"title-lg"}>{Masks.money(data?.subtotal.toString())} </Typography></TableCell>
                    </TableRow> }
                    {data?.income_tax ?
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align={"right"}><Typography level={"title-lg"}>Imposto de renda retido na fonte:</Typography></TableCell>
                        <TableCell><Typography level={"title-lg"}>{Masks.money(data?.income_tax.toString())} </Typography></TableCell>
                      </TableRow> : null }
                    {data?.percentage < 100 &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell colSpan={2} align={"right"}>
                        <Chip label={`Beneficiário possui somente ${data?.percentage}% do repasse`}/>
                      </TableCell>
                    </TableRow> }
                    <TableRow>
                      <TableCell>

                      </TableCell>
                      <TableCell align={"right"}><Typography sx={{fontWeight:"bold",}} level={"h4"} color={data?.total < 0 && "error.main" }> Total do Repasse:</Typography></TableCell>
                      <TableCell><Typography sx={{fontWeight:"bold", fontSize:"1.3rem"}} level={"h4"} color={data?.total < 0 && "error.main" }>{Masks.money(data?.total.toString())} </Typography> </TableCell>
                    </TableRow>
                  </TableBody>

                </Table>

              </>
            }
            {data?.has_debit &&
              <>
            <CardContent sx={{backgroundColor:"#FEF4E5"}}>
              <Typography gutterBottom variant="h5" component="div">
                Repasse em débito
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Deseja adicionar o valor do débito para descontar no repasse de {moment(data?.reference_date,'YYYY-MM-DD').add(1, 'months').format("MMM/YYYY")}?
              </Typography>
            </CardContent>
            <CardActions sx={{backgroundColor:"#FEF4E5"}}>

              <Button size="small" onClick={()=>{addDebitTransfer()}}>Sim, quero adicionar</Button>
            </CardActions>
              </>}
          </Card>
          <Card sx={{padding:"1rem", margin: "1rem " }}>
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", justifyContent:"space-between", padding: ".5rem 0 "}}>
                <Box sx={{display: "flex", alignItems: "center" }}>
                  <Article  sx={{
                    color: 'primary.main'
                  }}/>
                  <Typography sx={{marginLeft: ".5rem"}} color={"primary.main"} variant="h6">
                    Contrato</Typography>
                </Box>

              </Box>
              <Divider/>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell

                    >
                      Código
                    </TableCell>

                    <TableCell

                    >
                      Nome
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}
                    >
                      Contrato
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}

                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={data?.rent_contract.number}
                    hover
                    sx={{cursor:"pointer"}}
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`/contratos/${data?.rent_contract.id}`)}}
                      >
                      <TableCell>
                      {data?.rent_contract.number}
                      </TableCell>
                    <TableCell>
                      {data?.rent_contract.name}
                    </TableCell>
                    <TableCell sx={{
                      textAlign: "center",
                    }}>
                      {data?.rent_contract.contract_type}
                    </TableCell>
                    <TableCell sx={{
                      textAlign: "center",
                    }}>
                      {data?.rent_contract.status}
                    </TableCell>
                    <TableCell sx={{fontWeight: "bold", textAlign: "center"}}>
                      {Masks.money(data?.rent_contract.value.toString())}
                    </TableCell>

                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card sx={{padding:"1rem", margin: "1rem " }}>
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", justifyContent:"space-between", padding: ".5rem 0 "}}>
                <Box sx={{display: "flex", alignItems: "center" }}>
                  <Receipt  sx={{
                    color: 'primary.main'
                  }}/>
                  <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                    Fatura</Typography>
                </Box>

              </Box>
              <Divider/>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell

                    >
                      Nº da Fatura
                    </TableCell>

                      <TableCell

                      >
                        Imóvel
                      </TableCell>
                    { reference === 'payment' ?
                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}
                    >
                      Competência
                    </TableCell>
                    :
                    <TableCell
                        sx={{
                          textAlign: "center",

                        }}
                    >
                      Referência
                    </TableCell>
                    }
                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}
                    >
                      Vencimento
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}

                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableRow
                key={data.invoice.number}
                hover
                onClick={(e) => {
                e.stopPropagation()
                navigate(`/faturas/${data?.invoice.id}`)
              }}
                sx={{ 'cursor': 'pointer', }}
                >
                <TableCell>
                  <Box
                    sx={{
                      textAlign: "left",
                    }}
                  >

                    {data?.invoice.number}
                  </Box>
                </TableCell>
                <TableCell>
                  {data?.invoice.unit}
                </TableCell>
                  { reference === 'payment' ?
                <TableCell
                  sx={{
                  textAlign: "center",
                }}
                >
                  {data?.invoice.payment_month_reference}
                </TableCell>
                      :
                      <TableCell
                          sx={{
                            textAlign: "center",
                          }}
                      >
                        {data?.invoice.reference_month}
                      </TableCell>
                  }
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {data?.invoice.due_date
                    ? moment(data?.invoice.due_date).format('DD/MM/YYYY') : '-'
                  }
                </TableCell>
                <TableCell
                  align={'center'}
                >
                  {data?.invoice.status}


                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    textAlign: "center"
                  }}
                >
                  {data?.invoice.total.toString().includes('-') && '- '}
                  {Masks.money(data?.invoice.total.toString())}
                </TableCell>


              </TableRow>
              </Table>
            </CardContent>
          </Card>

          {user?.is_superuser && <NewInternalNotesCard  state={data?.internal_notes}/> }

        </Grid>
        <Grid md={4}>
          { data?.status.id !== "OPEN" && data?.status.id !== 'DEBIT' && data?.status.id !== 'FINALIZED' && <Card sx={{marginBottom: "1rem"}}>
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", justifyContent:"space-between", padding: ".5rem 0 "}}>
                <Box sx={{display: "flex", alignItems: "center" }}>
                <Alarm  sx={{
                  color: 'primary.main'
                }}/>
                <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                  Dados da Transferência</Typography>

                </Box>
                {data?.user && <Tooltip title={`Transferência realizada por ${data?.user.name}`}>
                  <Avatar alt={data?.user.name} src={data?.user?.photo}  sx={{ width: 24, height: 24, bgcolor: "secondary.main", fontSize:"small"  }} />
                </Tooltip> }
              </Box>

              <Divider/>


              <Table>
                <TableBody>
                  {data?.fitbank_status.id &&
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell sx={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <Chip
                      color={data?.fitbank_status.id === 2 ? 'success' :
                          data?.fitbank_status.id === "Settled" ? 'success' :
                          data?.fitbank_status.id === "Paid" ? 'success' :
                          data?.fitbank_status.id === 1 ? 'warning' :
                          data?.fitbank_status.id === 3 ? 'warning' :
                          data?.fitbank_status.id === 4 ? 'warning' :
                          data?.fitbank_status.id === 5 ? 'error' :
                          data?.fitbank_status.id === null ? 'error' :
                          data?.fitbank_status.id === "Canceled" ? 'error' :
                          data?.fitbank_status.id === "Cancel" ? 'error' :
                          data?.fitbank_status.id === "Created" ? 'info' :
                            data?.fitbank_status.id === 0 && 'info'
                      }
                      size="small"
                      variant="outlined"
                      label={data?.fitbank_status.value}/>
                      {data?.fitbank_status.id !== "Canceled" && data?.fitbank_status.id !== "Paid" && data?.fitbank_status.id !== "Settled"  &&
                      <Button onClick={()=>{handleCancelTransfer()}} size={"small"} color="error" startIcon={<CancelOutlined />}>Cancelar</Button> }
                    </TableCell>

                  </TableRow>
                  }
                  {data?.status_message && <TableRow>
                    <TableCell>Mensagem</TableCell>
                    <TableCell>{data?.status_message}</TableCell>
                  </TableRow> }
                  {data?.document_number && <TableRow>
                    <TableCell>Data </TableCell>
                    <TableCell>{moment(data?.auto_transfer_scheduled).format("DD/MM/YYYY")}</TableCell>
                  </TableRow> }
                  <TableRow>
                    <TableCell>Tipo</TableCell>
                    <TableCell>{data?.auto_transfer_type}</TableCell>
                  </TableRow>
                  {data?.document_number && <TableRow>
                    <TableCell>Recibo</TableCell>
                    <TableCell><Link href={data?.receipt} rel="noopener" target="_blank">Ver Recibo</Link></TableCell>
                  </TableRow> }
                </TableBody>
              </Table>
            </CardContent>

          </Card> }

          <Card >
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", padding: ".5rem 0 "}}>
              <ContactMailOutlined  sx={{
                color: 'primary.main'
              }} />
              <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                  Beneficiário</Typography>
              </Box>
              <Divider/>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>{data?.contract_recipient?.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>E-mail</TableCell>
                    <TableCell>{data?.contract_recipient?.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Telefone</TableCell>
                    <TableCell>{data?.contract_recipient?.phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CPF/CNPJ</TableCell>
                    <TableCell>{data?.contract_recipient?.cpf_cnpj}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>



            </CardContent>

          </Card>



          { data?.bank_account_recipient.account_type === "TED" && <Card sx={{marginTop: "1rem"}}>
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", padding: ".5rem 0 "}}>
                <AccountBalance  sx={{
                  color: 'primary.main'
                }} />
                <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                  Dados Bancarios:</Typography>
              </Box>

              <Divider/>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Banco</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tipo da conta</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank_account_type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Agência</TableCell>
                    <TableCell> {data?.bank_account_recipient.bank_branch}
                      {data?.bank_account_recipient.bank_branch_digit ?
                          '-' + data?.bank_account_recipient.bank_branch_digit : ''}</TableCell>

                  </TableRow>
                  <TableRow>
                    <TableCell>Conta</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank_account}{data?.bank_account_recipient.bank_account_digit && `-${data?.bank_account_recipient.bank_account_digit}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>



            </CardContent>

          </Card> }

          { data?.bank_account_recipient.account_type === "PIX" && <Card sx={{marginTop: "1rem"}}>
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", padding: ".5rem 0 "}}>
                <PixOutlined  sx={{
                  color: 'primary.main'
                }} />
                <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                  Dados do Pix:</Typography>
              </Box>

              <Divider/>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Banco</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Chave Pix</TableCell>
                    <TableCell>{data?.bank_account_recipient.pix_key_type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Chave</TableCell>
                    <TableCell>{data?.bank_account_recipient.pix_key_value}</TableCell>
                  </TableRow>

                </TableBody>
              </Table>



            </CardContent>

          </Card> }

        </Grid>

      </Grid> }
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle>Criar Repasse</DialogTitle>
        <Divider/>



          <Box
            noValidate
            component="form"

          >

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Beneficiário</TableCell>
                    <TableCell>{data?.contract_recipient?.name}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Banco</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank}</TableCell>
                  </TableRow>
                  { data?.bank_account_recipient.account_type === "PIX" &&
                    <TableRow>
                      <TableCell>Chave Pix</TableCell>
                    <TableCell>{data?.bank_account_recipient.pix_key_type}</TableCell>
                  </TableRow> }
                { data?.bank_account_recipient.account_type === "PIX" &&
                  <TableRow>
                    <TableCell>Chave</TableCell>
                    <TableCell>{data?.bank_account_recipient.pix_key_value}</TableCell>
                  </TableRow> }

                  { data?.bank_account_recipient.account_type === "TED" &&
                    <TableRow>
                    <TableCell>Tipo da conta</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank_account_type}</TableCell>
                  </TableRow> }
                  { data?.bank_account_recipient.account_type === "TED" &&
                    <TableRow>
                    <TableCell>Agência</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank_branch}-{data?.bank_account_recipient.bank_branch_digit}</TableCell>
                  </TableRow> }
                  { data?.bank_account_recipient.account_type === "TED" &&  <TableRow>
                    <TableCell>Conta</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank_account}{data?.bank_account_recipient.bank_account_digit && `-${data?.bank_account_recipient.bank_account_digit}`}</TableCell>
                  </TableRow> }
                  <TableRow>
                    <TableCell>Valor do repasse</TableCell>
                    <TableCell>{Masks.money(data?.total.toString())}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Data de Pagamento</TableCell>
                    <TableCell>

                        <MobileDatePicker
                          // openTo={'month'}
                          // views={['year', "month"]}


                          value={moment(dateInput, 'DD/MM/YYYY').format()}
                          onChange={(e)=> {
                            setDateInput(moment(e).format("DD/MM/YYYY"))
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "DD/MM/AAAA",
                                readOnly: true,

                              }}


                              required
                              sx={{
                                maxWidth: '200px',
                                mr: '0.5rem',
                              }}
                            />
                          )}
                        />


                    </TableCell>
                  </TableRow>
                <TableRow>
                    <TableCell>
                      <FormControlLabel
                          label={<Typography variant="body2">{"Enviar comprovante por E-mail?"}</Typography>}
                          sx={{
                            pl: 2,
                            pr: 2
                          }}
                          control={
                            <Switch
                                size="small"
                                onChange={(e) => {
                                  setSendEmail(true)
                                }}
                                name="Enviar por e-mail"/>}
                      />
                      <FormControlLabel
                          sx={{pl: 2, pr: 2}}
                          control={
                            <Switch
                                size="small"
                                disabled={!data?.whatsapp_send_message}
                                onChange={(e) => {
                                  setSendWhatsapp(true)
                                }}
                                name="tsapp"
                            />}
                          label={<Typography variant="body2">{"Enviar comprovante por Whatsapp?"}</Typography>}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
          </Box>

        <Divider />
        <DialogActions sx={{display:"flex", justifyContent:"space-between", padding: "2rem 1rem"}}>
          <Button variant="text" color={"secondary"} onClick={()=> handleDialogClose() }>Cancelar</Button>
          <Button variant="contained" disabled={isSaving} color={"primary"} onClick={()=> {
            createManualTransfer()
          } }>{isSaving ? "Salvando" : "Criar" }</Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSaving}
        // onClick={() => {
        //   if (isSaving === false){
        //     setActiveBackdrop(false)
        //   }
        //
        // }}
      >
        <CircularProgress color="inherit" />

      </Backdrop>
   </>

  )
}

export default SignedRecipientTransferDetail
