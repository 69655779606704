import { Box, palette, styled } from '@mui/system'
import {
  Card,
  CardContent,
  CardHeader, Chip,
  Dialog,
  Divider,
  Grid,
  Paper,
  Skeleton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Tooltip,
  Typography
} from '@mui/material'
import moment from 'moment'
import { getCondoId, getFilterDate, setFilterDate } from '../../../services/Main/AuthStorage'
import { useContext, useEffect, useState } from 'react'
import Context from '../../../contexts/Context'
import {
  ArticleOutlined,
  CurrencyExchange,
  DescriptionOutlined,
  PaidOutlined,
  PaymentsOutlined, PendingActionsOutlined,
  ReceiptOutlined, RequestQuoteOutlined
} from '@mui/icons-material'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import { api } from '../../../services/Main/Api'
import { useSearchParams } from 'react-router-dom'
import Masks from '../../../components/Shared/Masks'
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import OverduesCard from '../../../components/Shared/OverduesCard'
import Treemap from '../../../components/Charts/Treemap'
import * as React from 'react'
import Brasil from '../../../components/Shared/Brasil'
import SignedRecipientTransferMakeMenu from '../SignedRecipientTransfer/SignedRecipientTransferMakeMenu'
import NoResultsAnimation from '../../../components/Shared/animations/NoResultsAnimation'








const AdminDashboard = () => {

  const storageDate = getFilterDate()

  const { condoIdLogin } = useContext(Context)
  const [date, setDate] = useState(storageDate ? moment(new Date(storageDate)).format() : moment().format())
  const condo = getCondoId()

  const [result, setResult] = useState({})
  const [transactionYear, setTransactionYear] = useState({})
  const [transactionMonth, setTransactionMonth] = useState({})
  const [loadingResult, setLoadingResult] = useState(false)
  const [resultSixMonthsValue, setResultSixMonthsValue] = useState([])
  const [resultSixMonths, setResultSixMonths] = useState([])
  const [transactionQuantity, setTransactionQuantity] = useState([])
  const [transactionResult, setTransactionResult] = useState([])
  const [transactionQuantityValue, setTransactionQuantityValue] = useState([])
  const [billingCompany, setBillingCompany] = useState({
    xAxis: [],
    ySeries: []
  })
  const [transactionTotalMonth, setTransactionTotalMonth] = useState({
    xAxis: [],
    ySeries: []
  })
  const [invoiceCount, setInvoiceCount] = useState({
    valueMax: 0,
    value: 0,
    percentage: 0
  })

  const [transferCount, setTransferCount] = useState({
    valueMax: 0,
    value: 0,
    percentage: 0
  })
  const [searchParams, setSearchParams] = useSearchParams();

  function getData(){

    let filter = {}
    setLoadingResult(true)
    api.get(`analytics/rent/`, {params: filter})
      .then(response => {
        const data = response.data

        setResult(data)
        setLoadingResult(false)
        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getTransactionResult(){

    let filter = {}
    setLoadingResult(true)
    api.get(`fitbank/transaction/`, {params: filter})
      .then(response => {
        const data = response.data

        setTransactionResult(data)
        setLoadingResult(false)
        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getSixMonthsRentData(){

    let filter = {}

    api.get(`analytics/month-rent-contract/`, {params: filter})
      .then(response => {
        const data = response.data

        setResultSixMonths(data.months)
        setResultSixMonthsValue(data.values)
        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getTransactionQuantity(){

    let filter = {}

    api.get(`analytics/transaction-month-quantity/`, {params: filter})
      .then(response => {
        const data = response.data

        setTransactionQuantity(data.months)
        setTransactionQuantityValue(data.values)
        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getTransactionYear(){

    let filter = {}

    api.get(`analytics/transaction-year/`, {params: filter})
      .then(response => {
        const data = response.data

        setTransactionYear(data)

        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getTransactionMonth(){

    let filter = {}

    api.get(`analytics/transaction-month/`, {params: filter})
      .then(response => {
        const data = response.data

        setTransactionMonth(data)

        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getInvoiceAndTransfer(){

    let filter = {}

    api.get(`analytics/invoice-and-transfer/`, {params: filter})
      .then(response => {
        const data = response.data
        setInvoiceCount({
          valueMax: data.invoice_value_max,
          value: data.invoice_value,

        })

        setTransferCount({
          valueMax: data.transfer_value_max,
          value: data.transfer_value,

        })

        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getBillingCompany(){

    let filter = {}

    api.get(`analytics/billing-company/`, {params: filter})
      .then(response => {
        const data = response.data
        setBillingCompany({
          xAxis: data.months,
          ySeries: data.values,
        })



        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getTransactionMonthTotal(){

    let filter = {}

    api.get(`analytics/transaction-month-value/`, {params: filter})
      .then(response => {
        const data = response.data
        setTransactionTotalMonth({
          xAxis: data.months,
          ySeries: data.values,
        })



        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  useEffect(()=>{
    getData()
    getSixMonthsRentData()
    getInvoiceAndTransfer()
    getBillingCompany()
    getTransactionQuantity()
    getTransactionMonthTotal()
    getTransactionYear()
    getTransactionMonth()
    getTransactionResult()
  }, [])

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));


  return (
    <>

      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item xs={12} md={4} lg={4}>
          <Item >
            <PaidOutlined sx={{ color:'primary.main', flexGrow:1  }} />
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36', marginBottom: "1rem" }}> Faturas</Typography>

            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
                <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                  {result?.total_customer_invoice && Masks.money(result?.total_customer_invoice.toString())}
                </Typography>
              }

            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Item >
            <ReceiptOutlined sx={{color:'primary.main', flexGrow:1  }} />
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36', marginBottom: "1rem" }}>Faturas pagas</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
                <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }}>
                  {result?.total_customer_invoice_paid && Masks.money(result?.total_customer_invoice_paid.toString())}
                </Typography>
              }

            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Item >
            <CurrencyExchange sx={{ color:'primary.main', flexGrow:1  }} />
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Número de Clientes</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
                <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                  {result?.customer_invoice_number && result?.customer_invoice_number}
                </Typography>
              }
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item xs={12} >
              <Divider sx={{fontWeight: 'bold', fontSize: "1.5rem", marginTop: "2rem"}}>Últimos 30 dias</Divider>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Total de transações</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction?.total && Masks.money(transactionMonth?.transaction?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_in?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Total de entrada</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_in?.total && Masks.money(transactionMonth?.transaction_in?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_out?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Total de Saída</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_out?.total && Masks.money(transactionMonth?.transaction_out?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_boleto_in?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Boleto(Entrada)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_boleto_in?.total && Masks.money(transactionMonth?.transaction_boleto_in?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_boleto_out?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Boleto(Saída)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_boleto_out?.total && Masks.money(transactionMonth?.transaction_boleto_out?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_pix_in?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>PIX(Entrada)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_pix_in?.total && Masks.money(transactionMonth?.transaction_pix_in?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_pix_out?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>PIX(Saída)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_pix_out?.total && Masks.money(transactionMonth?.transaction_pix_out?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_ted_in?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>TED(Entrada)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_ted_in?.total && Masks.money(transactionMonth?.transaction_ted_in?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_ted_out?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>TED(Saída)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_ted_out?.total && Masks.money(transactionMonth?.transaction_ted_out?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <Divider sx={{fontWeight: 'bold', fontSize: "1.5rem", marginY: "2rem"}} />
        </Grid>
        <Grid item  xs={12} md={6} lg={8}>
          <Card>
            <CardHeader title={'Número transações por mês'}/>
            <CardContent>
              <BarChart
                sx={{display: "block"}}
                colors={['#f50863']}
                xAxis={[
                  {
                    id: 'barCategories',
                    data: transactionQuantity,
                    scaleType: 'band',

                  },
                ]}
                series={[
                  {
                    data: transactionQuantityValue,
                  },
                ]}

                height={500}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card>
            <CardHeader title={'Onde estamos'}/>
          <Box sx={{display: "flex", justifyContent: "center", paddingBottom: 4}}>
          <Brasil  />
          </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardHeader title={'Total de transações por mês'}/>
            <CardContent>
              <LineChart
                xAxis={[{
                  data: transactionTotalMonth.xAxis,
                  scaleType: 'point',
                  valueFormatter: (value) => (moment(value, 'MM/YYYY').format('MMM/YYYY')),
                }]}
                margin={{ top: 5, right: 30, bottom: 30, left: 40 }}
                colors={['#f50863']}
                series={[
                  {
                    data: transactionTotalMonth.ySeries,
                    valueFormatter: (value) => (Masks.money(value.toString())),
                    area: true,
                  },
                ]}
                yAxis={[
                  {
                    valueFormatter: (value) => (`${value / 100000}k`),
                  },
                ]}

                height={500}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item xs={12} >
              <Divider sx={{fontWeight: 'bold', fontSize: "1.5rem", marginTop: "2rem"}}> Últimos 12 meses</Divider>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionYear?.transaction?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Total de transações</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionYear?.transaction?.total && Masks.money(transactionYear?.transaction?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionYear?.transaction_in?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Total de entrada</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionYear?.transaction_in?.total && Masks.money(transactionYear?.transaction_in?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionYear?.transaction_out?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Total de Saída</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionYear?.transaction_out?.total && Masks.money(transactionYear?.transaction_out?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionYear?.transaction_boleto_in?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Boleto(Entrada)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionYear?.transaction_boleto_in?.total && Masks.money(transactionYear?.transaction_boleto_in?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionYear?.transaction_boleto_out?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Boleto(Saída)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionYear?.transaction_boleto_out?.total && Masks.money(transactionYear?.transaction_boleto_out?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionYear?.transaction_pix_in?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>PIX(Entrada)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionYear?.transaction_pix_in?.total && Masks.money(transactionYear?.transaction_pix_in?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionYear?.transaction_pix_out?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>PIX(Saída)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionYear?.transaction_pix_out?.total && Masks.money(transactionYear?.transaction_pix_out?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionYear?.transaction_ted_in?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>TED(Entrada)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionYear?.transaction_ted_in?.total && Masks.money(transactionYear?.transaction_ted_in?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionYear?.transaction_ted_out?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>TED(Saída)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionYear?.transaction_ted_out?.total && Masks.money(transactionYear?.transaction_ted_out?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>

          <Card>
            <CardHeader title={'Número de novos contratos'}/>
            <CardContent>
              <BarChart
                sx={{display: "block"}}
                colors={['#f50863']}
                xAxis={[
                  {
                    id: 'barCategories',
                    data: resultSixMonths,
                    scaleType: 'band',

                  },
                ]}
                series={[
                  {
                    data: resultSixMonthsValue,
                  },
                ]}

                height={500}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardHeader title={'Últimas transações'}/>
            <TableContainer>
              {transactionResult?.results?.length > 0  ?
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Empresa</TableCell>

                      <TableCell>Data</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Evento</TableCell>
                      <TableCell>Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>


                    { transactionResult.results.map(item =>
                      <TableRow
                        key={item.id}
                        hover
                        sx={{'cursor': 'pointer'}}

                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            TRA-{item.id}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.company}
                          </Box>
                        </TableCell>


                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {moment(item.reference_date).format("DD/MM/YYYY [às] HH:mm:ss")}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.description}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.status === "Entrada" ?
                              <Chip label={item.event} color={"success"} size="small" variant="outlined" />
                              :
                              <Chip label={item.event} size="small" variant="outlined" />
                            }




                          </Box>
                        </TableCell>

                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                            color={item?.value < 0 && "error.main" }
                          >
                            {Masks.money(item?.value.toString())}

                          </Box>
                        </TableCell>





                      </TableRow>
                    )}

                  </TableBody>
                </Table>
                :
                <NoResultsAnimation />
              }
            </TableContainer>
          </Card>
        </Grid>

      </Grid>


    </>)
}

export default AdminDashboard